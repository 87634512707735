import { setupIonicReact, IonApp } from '@ionic/react'
import React from 'react'
import { IonReactRouter } from '@ionic/react-router'
import { SafeArea } from '@capacitor-community/safe-area'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/styles.scss'

setupIonicReact()

import Main from './Main'
import AppUrlListener from './AppUrlListener'

// SafeArea.enable({
//   config: {
//     customColorsForSystemBars: true,
//     statusBarColor: '#00000000', // transparent
//     statusBarContent: 'light',
//     navigationBarColor: '#00000000', // transparent
//     navigationBarContent: 'light',
//   },
// })

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener />
        <Main />
      </IonReactRouter>
    </IonApp>
  )
}

export default App
